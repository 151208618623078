import { lazy } from "react";

import { linkResolver } from "../src/utilities/prismic-linkResolver";

export default [{
  repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
  linkResolver,
  componentResolver: {
    blog: lazy(() => import("../src/templates/Blog")),
    builder_page: lazy(() => import("../src/templates/BuilderPage")),
    homepage: lazy(() => import("../src/templates/Homepage")),
    post: lazy(() => import("../src/templates/Post")),
    project: lazy(() => import("../src/templates/Project")),
    service: lazy(() => import("../src/templates/Service")),
    simple_page: lazy(() => import("../src/templates/SimplePage")),
  },
}];
