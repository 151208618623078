exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/Blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-builder-page-js": () => import("./../../../src/templates/BuilderPage.js" /* webpackChunkName: "component---src-templates-builder-page-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/Post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/Projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/Service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/SimplePage.js" /* webpackChunkName: "component---src-templates-simple-page-js" */)
}

