module.exports = {
  siteUrl: "https://www.manifest.gr",
  siteLang: "el-gr", // This should be the same as Prismic master locale
  title: "Manifest Services SA",
  shortTitle: "Manifest",
  description: "Οι ηγέτες στο Facility Management.",
  logoUrl: "https://www.manifest.gr/images/logo.jpg",

  // Values used in manifest
  siteBackgroundColor: "#fcfcfc",
  siteThemeColor: "#09203e",
  siteIcon: "./src/images/icon.png",

  // Image Details used as fallback at metadata
  defaultImage: "https://www.manifest.gr/images/logo.jpg",
  defaultImageWidth: "1200",
  defaultImageHeight: "630",
  defaultImageAlt: "Manifest Services SA",
};
