/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 * @returns URL for the provided Prismic document.
 */

const defaultLanguage = require("../../config/site-config").siteLang;

exports.linkResolver = (doc) => {
  switch (doc.type) {
    case "homepage": {
      return doc.lang === defaultLanguage ? "/" : `/${doc.lang.split("-")[0]}/`;
    }
    case "service": {
      const services = doc.data?.services;
      let parentSlug = "";
      services?.forEach((parent) => {
        if (parent?.service?.uid) {
          parentSlug += `/${parent.service.uid}`;
        }
      });

      return doc.lang === defaultLanguage
        ? `${parentSlug}/${doc.uid}/`
        : `/${doc.lang.split("-")[0]}${parentSlug}/${doc.uid}/`;
    }
    case "all_projects": {
      return doc.lang === defaultLanguage
        ? `/project/`
        : `/${doc.lang.split("-")[0]}/project/`;
    }
    case "project": {
      return doc.lang === defaultLanguage
        ? `/project/${doc.uid}/`
        : `/${doc.lang.split("-")[0]}/project/${doc.uid}/`;
    }
    case "category": {
      return doc.lang === defaultLanguage
        ? `/category/${doc.uid}/`
        : `/${doc.lang.split("-")[0]}/category/${doc.uid}/`;
    }
    case "projects":
    case "blog":
    case "post":
    case "simple_page": {
      return doc.lang === defaultLanguage
        ? `/${doc.uid}/`
        : `/${doc.lang.split("-")[0]}/${doc.uid}/`;
    }
    case "builder_page": {
      const categories = doc.data?.categories;
      let parentSlug = "";
      categories?.forEach((parent) => {
        if (parent?.category?.uid) {
          parentSlug += `/${parent.category.uid}`;
        }
      });

      return doc.lang === defaultLanguage
        ? `${parentSlug}/${doc.uid}/`
        : `/${doc.lang.split("-")[0]}${parentSlug}/${doc.uid}/`;
    }
    default: {
      return "/";
    }
  }
};
